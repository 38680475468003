<template>
  <div id="app">
    <!-- <bar /> -->
    <!-- elevate-on-scroll -->

    <v-app-bar height="80" class="shado" 
    elevate-on-scroll
      scroll-off-screen
      color="white"
      dense
      dark
      app
    >
    <v-container class="pa-md-0 fill-height ">
      <v-btn text @click="home()">
      <v-img
              alt=""
              class="shrink"
              src="@/assets/altru-logo.svg" width="118px" 
            ></v-img>
          </v-btn>

      <v-spacer></v-spacer>

      <v-btn
          min-width="15"
          height="37"
          outlined
          color="grey"
          class="hidden-md-and-up togglemenu-icon pa-2 py-3 my-3"
          @click="toggleMenu = !toggleMenu"
        >
          <div v-if="toggleMenu">
            <img
              src="@/assets/nav-icon.svg"
              alt="menuicon"
              class="bgimage mt-1 text-center"
            />
          </div>
          <v-icon :ripple="false" v-else color="black" size="18" 
            >mdi-close</v-icon
          >
        </v-btn>
        <p v-if="toggleMenu"></p>
        <v-card
          enable-resize-watcher
          max-height="600"
          class="shado
            position-fixed
            mt-2
            d-lg-none d-xl-none d-md-none
            overflow-y-auto
          " style="background-color: white;"
          v-else
          v-model="drawer"
          :absolute="toggleMenu"
          :attach="'#toolbar'"
          :content-class="'elevation-0 mt-5'"
          min-width="100%"
        >

        <v-list style="background-color:white !important">
       
       <v-list-item-group
       >
      <v-list-item 
         
         >
           
           <v-list-item-content>
             <v-list-item-title class="black--text font-weight-medium">Products</v-list-item-title>
           </v-list-item-content>
           
         </v-list-item>
         <v-list-item>
           
           <v-list-item-content>
             <v-list-item-title class="black--text font-weight-medium">Services</v-list-item-title>
           </v-list-item-content>
           
         </v-list-item>
       </v-list-item-group>
     </v-list>

         
          
        </v-card>
        
        



      <div class="d-none d-md-flex">
                <v-btn  href="#productcall"
                  dark
                  class="
                    py-9
                    hoverchange-color
                    text-center
                    black--text
                    font-weight-medium text-capitalize
                    subtitle-2
                  "
                  :ripple="false"
                  v-bind="attrs"
                  v-on="on"
                  plain
                  text elevation-0
                  dense style="background-color:white !important"
                >
                PRODUCTS
                </v-btn>
                <v-btn  href="#sevices"
                  dark
                  class="mr-7
                    py-9
                    hoverchange-color
                    text-center
                    black--text
                    font-weight-medium text-capitalize
                    subtitle-2
                  "
                  :ripple="false"
                  v-bind="attrs"
                  v-on="on"
                  plain
                  text elevation-0
                  dense style="background-color:white !important"
                >
                SERVICES
                </v-btn>
                <v-btn class="black--text rounded-xl shado text-capitalize mt-5" color="#ffffff" >Schedule Call</v-btn>
            
             
           </div>  
    </v-container>
   
    </v-app-bar>
<div v-if="homeview">
    <div class="backimg text-center my-16 pt-15 pb-8">
      <div class="fonth1 font-weight-regular text-center pt-16" color="#141414">
        <p class="pa-0 my-0">New-ZEN Engineering Solutions to solve complex</p>
        <p class="pa-0 my-0">business challenges.</p>

      </div>
      <div>
        <p class="text-center fonth8 font-weight-medium my-4" color="#545454">Design, Testing, Development, Consultants,
          Trainings</p>

      </div>
      <v-btn id="productcall" rounded elevation="16" class="my-5 ">
        Schedule Call
      </v-btn>
    </div>
    <v-container class=" ">
      <div class="">
        <div class="">
          <p class="text-center font-weight-medium subtitle-1 "
            style="color:#141414 !important ; letter-spacing: 2px !important;">PRODUCTS</p>
          <v-row class="mt-10 justify-md-center">
            <v-col cols="12" md="5" sm="6">
              <v-card class="pa-7 shado" height="100%">
                <p class="font-weight-medium " style="font-size: 25px !important;">Copy Trade</p>
                <p class="subtitle-1">Manage all your trading activity under one roof.<br></p>
                <p>Find insights and key updates for your holdings.</p>
                <div justify="center">
                <v-dialog v-model="pricebtn" width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mt-2 mb-4 rounded-pill text-capitalize white--text" v-bind="attrs" v-on="on" large
                      color="#141414"><span class="font-weight-bold ">Buy Copy Trade</span></v-btn>
                      <!-- <v-row></v-row> -->
                  </template>
                  <v-card >
                    <div class="pa-5">
                    <div class="text-center headline font-weight-bold py-2">Copy Trade</div>
                    <v-divider></v-divider>
                    <v-row>
                      <v-col cols="6">
                     
                      <div class="ma-4 title text-center">
                        <p class="title">Master Account</p>
                        <p>	&#8360; 1000</p>
                      </div>

                      <v-card-actions>
                        <v-btn color="primary" text @click="pricebtn = false" block>
                          Buy Now
                        </v-btn>
                      </v-card-actions>
                      </v-col>
                      <v-divider vertical class="mt-3"></v-divider>
                      <v-col cols="6" >
                     
                      <div class="ma-4 title text-center">
                        <p>Client Account</p>
                        <p>	&#8360; 350</p>
                      </div>

                      <v-card-actions>
                        <v-btn color="primary" text @click="pricebtn = false" block>
                          Buy Now
                        </v-btn>
                      </v-card-actions>
                  </v-col>
                    </v-row>
                  </div>
                  </v-card>
          
                </v-dialog>
              </div>
              </v-card>
            </v-col>
            <v-col col="12" md="5" sm="6">
              <v-card class="pa-7 shado" height="100%">
                <v-img alt="" class="" src="@/assets/wyselylogo.svg" width="118px"></v-img>
                <p class="subtitle-1 text-start font-weight-normal pt-4">One simple tool to track and build your wealth
                </p>
                <p class="subtitle-1">Manage all your trading activity under one roof.Find insights and key updates for
                  your holdings.</p>
                <v-btn href="https://app.wysely.in/" id="sevices"
                  class="mt-2 mb-4 rounded-pill text-capitalize white--text" large color="#141414"><span
                    class="font-weight-bold ">Lets Get wysely</span></v-btn>
                   
              </v-card>
            </v-col>
          </v-row>
          <div id="sevices"></div>
          <div id="v-tabs" class="mt-lg-16 pt-16 mb-8">
            <p class="text-center display-0 mb-10 font-weight-medium subtitle-1"
              style="color: #141414; letter-spacing: 2px !important;">SERVICES</p>
            <v-row>
              <v-col cols="12" md="4">
                <v-card v-for="(tab) in tabs" :key="tab.title" @click="selectedTab = tab.title"
                  :class="{ active: selectedTab == tab.title }" class="pa-4 mb-3 tabitemschange">
                  <div>
                    <h3 class="tab-item__heading font-weight-medium">{{ tab.title }}</h3>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="8">

                <template v-if="selectedTab == 'Software Design'">
                  <p class="subtitle-1 font-weight-normal" id="software" style=" color: #545454 !important;">
                    Software design is the process of creating software programs that meet specific business
                    requirements.<br> At our company, we understand that every business has unique needs and requirements,
                    and we work closely with our clients to understand their business processes and goals.<br> <br>Our
                    team of software designers has extensive experience in creating custom software solutions for a wide
                    range of industries.<br>

                    We use the latest software development methodologies to ensure that our software designs are
                    efficient, reliable, and secure.<br> <br> Our software designers use agile development methodologies,
                    which allow us to create software programs quickly and efficiently.<br> We also use DevOps
                    methodologies, which allow us to automate the software development process and ensure that our
                    software programs are tested thoroughly before they are released.
                  </p>
                </template>
                <template v-if="selectedTab == 'Product Development'">
                  <p class="subtitle-1 font-weight-normal" id="Development" style=" color: #545454 !important;">
                    Product development is the process of creating new products or improving existing ones.<br> At our
                    company, we specialize in product development for software-based products. We work closely with our
                    clients to understand their product requirements and goals.<br> <br> Our team of product developers
                    has extensive experience in creating software-based products for a wide range of industries.<br>

                    We use the latest software development tools and methodologies to ensure that our products are
                    efficient, reliable, and secure.<br> <br> Our product developers use agile development methodologies,
                    which allow us to create software-based products quickly and efficiently. We also use DevOps
                    methodologies, which allow us to automate the product development process and ensure that our products
                    are tested thoroughly before they are released.

                  </p>
                </template>
                <template v-if="selectedTab == 'Testing'">
                  <p class="subtitle-1 font-weight-normal" style=" color: #545454 !important;">Testing is the process of
                    verifying that software programs and products meet specific requirements and function as expected. At
                    our company, we offer testing services to ensure that your software programs and products are
                    functioning as expected and meeting your business requirements.<br>

                    Our team of expert testers will thoroughly test your software programs and products to identify any
                    bugs or issues and provide you with a detailed report. We use the latest testing tools and
                    methodologies to ensure that our testing services are efficient, reliable, and secure. <br><br>Our
                    testers use manual and automated testing methodologies to ensure that your software programs and
                    products are tested thoroughly before they are released.

                  </p>
                </template>

                <template v-if="selectedTab == 'Technical Solutions'">
                  <p class="subtitle-1 font-weight-normal" style=" color: #545454 !important;">Technical solutions are
                    services that help businesses optimize their operations and achieve their goals. At our company, we
                    offer technical solutions to help businesses stay ahead of the competition and achieve success.<br>
                    Our team of consultants has a wealth of experience in a wide range of industries and can provide
                    expert guidance and advice to help you achieve success.<br>

                    We offer technical solutions in areas such as cloud computing, cybersecurity, and data analytics.
                    <br><br>Our technical solutions are designed to help businesses improve their operations, reduce
                    costs, and increase revenue. We work closely with our clients to understand their business
                    requirements and goals and provide customized technical solutions that meet their specific needs.

                  </p>
                </template>

                <template v-if="selectedTab == 'Technical Training'">
                  <p class="subtitle-1 font-weight-normal" style=" color: #545454 !important;">Technical training is the
                    process of providing employees with the skills and knowledge they need to succeed in their jobs.<br>
                    At our company, we offer technical training services to help businesses stay up-to-date with the
                    latest technologies and trends.<br><br> We provide customized training programs that are tailored to
                    your business needs and can help your employees acquire the skills and knowledge they need to
                    succeed.<br>

                    We offer technical training in areas such as software development, cloud computing, cybersecurity, and
                    data analytics.<br><br> Our training programs are designed to be interactive and hands-on, allowing
                    your employees to learn by doing. We work closely with our clients to understand their business
                    requirements and goals and provide customized training programs that meet their specific needs.

                  </p>
                </template>
              </v-col>
            </v-row>

          </div>




        </div>


      </div>

    </v-container>

    <v-card class="pb-10 mb-lg-16 " elevation="0">
      <v-container>

        <div class="about">

          <div class="pt-5 mx-md-2 px-md-7">

            <v-card class="pa-10 mx-lg-16 mt-md-15 mb-5 shado ">
              <p class="font-weight-medium subtitle-1 text-center "
                style="color: #141414; letter-spacing: 2px !important;">ABOUT US</p>


              <p class="subtitle-1 font-weight-regular ">Our team of experts has extensive experience in creating custom
                software solutions for a wide range of industries. Whether you need software for your business operations,
                web or mobile applications, or any other software-related services, we are here to help.
                We understand that every business has unique needs and requirements, which is why we work closely with our
                clients to understand their business processes and goals. Our team of software designers and developers
                will work with you to create a customized software solution that meets your specific needs and fits
                seamlessly into your business operations.
                In addition to software design and development, we also provide testing services to ensure that your
                software is functioning as expected and meeting your business requirements. We have a team of expert
                testers who will thoroughly test your software to identify any bugs or issues and provide you with a
                detailed report.
                We also provide technical solutions and consulting services to help businesses optimize their operations
                and achieve their goals. Our team of consultants has a wealth of experience in a wide range of industries
                and can provide expert guidance and advice to help you achieve success.
                Finally, we offer technical training services to help businesses stay up-to-date with the latest
                technologies and trends. We provide customized training programs that are tailored to your business needs
                and can help your employees acquire the skills and knowledge they need to succeed.
                At our company, we are committed to providing the highest quality software design, product development,
                testing, technical solutions, training, and consulting services to our clients. Contact us today to learn
                more about how we can help your business succeed!
              </p>

            </v-card>
          </div>

        </div>
      </v-container>
    </v-card>
  </div>
  <div v-else-if=" prof">
    <v-container>
    <div class="mt-15 pt-1">
            <!-- <div class="ma-10 "> -->
              <div class="my-2">
                <p class="text-left font-weight-medium title " style="color:#141414 !important ; letter-spacing: 2px !important;">Refund Polciy</p>
                <v-divider ></v-divider>
              </div>
               <p class="my-4"> To be eligible for a refund, you must request a refund within 2 days of your purchase.
To request a refund, please contact our customer support team by email at <span><a>altrusolution@gmail.com</a></span> 
Refunds will be issued to the original payment method used for the purchase.
We reserve the right to refuse a refund if we suspect fraudulent activity or abuse of our refund policy.
If you have received a refund, you will no longer have access to the purchased webinars and Trainings.
We reserve the right to modify or amend this refund policy at any time. If we make changes, we will post the updated policy on this page.
If you have any questions or concerns about our refund policy, please contact our customer support team by email <sapn><a>altrusolution@gmail.com.</a></sapn>
</p>
            </div>
           
            </v-container>
              </div>
              <div v-else-if="refund">
                <v-container>
                <div class="mt-15 pt-1">
              <div class="my-5">
                <p class="text-left font-weight-medium title " style="color:#141414 !important ; letter-spacing: 2px !important;">Terms Service</p>
              <v-divider ></v-divider>  
              </div>
                <p>
                    Welcome to Altru. By using our website and purchasing our Products and Trainings, you agree to comply with our terms of service. Our Products & Trainings are for informational and educational purposes only, and we do not provide any guarantees or warranties as to their accuracy or completeness. We reserve the right to modify or update our pricing, or website at any time. You are solely responsible for your use of our Trainings and any consequences that may result. We are not liable for any damages or losses that may occur as a result of your use of our Trainings. You also agree to comply with all applicable laws and regulations when using our website. If you have any questions or concerns about our terms of service, please contact us.
                </p>
            </div>
    
</v-container>
              </div>
    <v-card class="elevation-0 rounded-0 mt-10 pa-2 pa-sm-0 " style="background-color: #c7f21d !important;">
      <v-container class="">
        <v-row class="mt-10 mb-2">
          <v-col cols="12" sm="4" class="">
            <v-btn text @click="home()"  color="#c7f21d">
            <v-img  alt="" class="ml-n2" background-color="white" src="@/assets/altru-logo.svg" width="118px"
              height=""></v-img>
            </v-btn>
            <div class="pt-2 mb-5 ml-n3 mt-4">
              <v-btn text icon v-for="(item, i) in items" :key="i" :href="item.url" target="_blank"
                rel="noopener nofollow" class="white--text rounded-circle pa-6">
                <v-icon size="27px" color="#141414">
                  {{ item.icon }}
                </v-icon>
              </v-btn>
            </div>

            <div class="">



              <p class="fontcol"><v-icon medium color="#141414">
                  mdi-phone
                </v-icon>&nbsp;&nbsp;&nbsp; (+91) 93 8010 8010</p>
              <p class="fontcol"><v-icon medium color="#141414">
                  mdi-email
                </v-icon>&nbsp;&nbsp;&nbsp; assist@altru.com</p>


            </div>

          </v-col>
          <v-col cols="12" sm="4">
            <p class="title fontcol">Products</p>
            <p class="subtitle-1 font-weight-regular text-capitalize" style="text-decoration: none !important;"><a
                class="text-capitalize font-weight-normal fontcol" style="text-decoration: none!important;">Copy trade</a>
              <br><a class="text-capitalize font-weight-normal fontcol" href="https://app.wysely.in/"
                style="text-decoration: none!important;">wysely</a></p>
          </v-col>
          <v-col cols="12" sm="4">
            <p class="title fontcol">Services</p>

            <a class="py-0 my-1" href="#sevices" style="text-decoration: none !important;">
              <p class="d-flex flex-column my-1 py-0 flex-start subtitle-1 font-weight-regular fontcol"
                v-for="(tab) in tabs" :key="tab.title" @click="selectedTab = tab.title"
                :class="{ active: selectedTab == tab.title }">
                {{ tab.title }}
              </p>
            </a>


          </v-col>

        </v-row>
        <v-divider color="#141414"></v-divider>
        <v-row>
          <v-col cols="6">

            <p class="mb-0 text-left body-2 pt-3 fontcol font-weight-bold" color="#141414">&#169; {{ new
              Date().getFullYear() }} ALTRU.
              All rights reserved
            </p>
          </v-col>
          <v-col cols="6" class="" align="right">
            <v-btn @click="refer()" id="refunding" text dense class="my-1 body-2 font-weight-bold">Refund Polciy</v-btn>
            <v-btn @click="profile()" text dense class="my-1 body-2 font-weight-bold">Terms of Service</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>   
  
  </div>
</template>
<script>


export default {
  data() {
    return {
      awesome: true,
      drawer: false,
      toggleMenu: true,
      pricebtn: false,
      pricebtn2: false,
      refund:false,
      homeview:false,
      prof:false,
      itemsssss: [
        { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Audience', icon: 'mdi-account' },
        { text: 'Conversions', icon: 'mdi-flag' },
      ],

      items: [
        {
          icon: 'mdi-facebook',
          //  url: 'https://www.facebook.com/zebuetrade/'
        },
        {
          icon: 'mdi-youtube',
          // url: 'https://www.youtube.com/channel/UCKbEVG1fH1TwkNDe6OM-zxg',
        },
        {
          icon: 'mdi-twitter',
          // url: 'https://twitter.com/zebuetrade?lang=en', 
        },

        {
          icon: 'mdi-instagram',
          //  url: 'https://www.instagram.com/zebu_official/', 
        },
        {
          icon: 'mdi-pinterest',
          //  url: 'https://in.pinterest.com/ZebuMarketing/',
        },

      ],
      tabs: [

        {
          title: "Software Design",
          // link : "Software Design"
        },
        {
          title: "Product Development",
        },
        {
          title: "Testing",
        },
        {
          title: "Technical Solutions",
        },
        {
          title: "Technical Training",
        },


      ],
      selectedTab: "Software Design",

    };
  },
  mounted(){
    this.home();
  },
  methods: {
    profile() {
      this.refund= true;
      this.homeview = false;
      this.prof =false;
      window.scrollTo(0,0);
    },
    refer() {
      this.refund= false;
      this.homeview = false;
      this.prof =true;  
      window.scrollTo(0,0);
    },
    home() {
      this.refund= false;
      this.homeview = true;
      this.prof =false;
      window.scrollTo(0,0);
    }
  },


};
</script>



<style>
*,
html,
body .v-application,
.v-application .display-1,
.v-application .headline,
.v-application .title,
.v-application .subtitle-1,
.v-application .subtitle-2,
.v-application .body-1,
.v-application .body-2,
.v-application .caption,
.v-application .overline {
  font-family: 'Roboto Slab', serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
</style>

<style>
.shado {
  box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%) !important;
}

.tabitemschange {
  background: white;
  border-left: 5px solid #D5DADF;
  background-color: transparent !important;
  box-shadow: 0 0.375rem 2.5rem 0 rgb(140 152 164 / 13%) !important;
  padding: 30px;
  border-radius: 3px;
  cursor: pointer;
  transition: all .2s ease;
  margin-bottom: 15px;
}

.tabitemschange:hover,
.tabitemschange.active {
  box-shadow: 0px 3px 3px 2px rgba(213, 218, 223, 0.35);
  border-left: 5px solid #377dff;
  background-color: white !important;
}

.cardchangehov {
  box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%) !important;
}

.cardchangehov:hover {
  transition: 0.3s !important;
  transform: translate(0, -3px) !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

.fontcol {
  color: #141414 !important;
}


.backimg {
  background-image: url("@/assets/Myproject-2.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100% !important;
  /* border-bottom-left-radius: 50px; */
}

.fonth1 {
  /* font-size: 50px; */
  font-size: 37px;
  color: #141414 !important;
}

.about {
  background-image: url("@/assets/aboutimg.svg") !important;
  height: 100% !important;
  width: 100% !important;
  background-repeat: no-repeat;
  background-position: center;
}

.fonth8 {
  font-size: 20px;
  color: #545454 !important;
}

.bgcolor2 {
  /* background-color: #b3b3b3; */
  /* background: rgb(223, 223, 223);
background: linear-gradient(152deg, rgba(221,218,218,0.8519782913165266) 52%, rgba(227,226,225,1) 100%, rgba(227,226,225,0.7847514005602241) 100%); */
  background-color: rgb(245, 245, 245, 0.938);
  background-image: linear-gradient(rgb(245, 245, 245, 0.938), rgba(245, 245, 245, 0.938));
}</style>
