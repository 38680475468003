import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
// import SideNav from '../views/SideNav.vue'
// import Refundpolicy from '../views/RefundPolciy.vue'
// import bar from '../views/headerBar.vue'
// import Terms from '../views/TermsService.vue'
// import footerView from '../views/footerView.vue'

Vue.use(VueRouter)

const routes = [
 
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },

  // {
  //   path: '/SideNav',
  //   name: 'SideNav',
  //   component: SideNav,
  // },

  {
    path: '/about',
    name: 'about',
    component: AboutView
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  // {
  //   path: '/refund',
  //   name: 'refund',
  //   component : Refundpolicy
  // },
  // {
  //   path : '/app',
  //   name: 'app',
  //   component : bar
  // },
  // {
  //   path: '/termsservice',
  //   name: 'termsservice',
  //   component: Terms
  // },
  // {
  //   path:'/footer',
  //   name: 'footer',
  //   component: footerView
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
